import React from 'react'
import './Footer.css'

export default () => (
  <div>
    <h2 className="taCenter">
      <a
        href="https://twitter.com/BASN_PR?ref_src=twsrc%5Etfw"
        className="twitter-follow-button"
        data-show-count="false"
      >
        Follow Us
      </a>
    </h2>
    <br />
    <footer className="footer">
      <div className="container taCenter">
        <span>© Copyright {new Date().getFullYear()} All rights reserved.</span>
      </div>
    </footer>
  </div>
)
